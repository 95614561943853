<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="page_con">
          <h1 class="con_ttl center">
            <span>회원 가입 안내</span>
          </h1>
          <div class="inform center">
            <div>위즈니는 19세 이상의 회원만 가입할 수 있습니다.</div>
          </div>
          <div class="con_btn">
            <div class="btn_wrap">
              <button class="btn" @click="fnConfirm">확인</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { componentState } from './index.js'

export default {
  name: 'regist-noti-index',
  setup(props) {
    return { ...componentState(props) }
  }
}
</script>

<style scoped></style>
